import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestService } from './service/request_service';
import { catchError, finalize, of, tap } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import Swal from 'sweetalert2';
import { InteractionType } from '@azure/msal-browser';

@Component({
    selector: 'app-request',
    templateUrl: './request.component.html',
    styleUrls: ['./request.component.scss'],
})
export class RequestComponent {
    public requestService: RequestService;
    public translateService: TranslateService;
    public messageService: MessageService;
    public loading: boolean = true;
    public currentUserName: string = '';
    public currentUserMail: string = '';
    public componentInstance: AppComponent;
    public submitErrorMessage: string = 'Compila tutti i campi richiesti.';
    public submitError: boolean = false;
    public invalidFile: boolean = false;
    public availableCategories: DropdownItem[] = [];
    public selectedCategories: number[] = [];
    public amount: number;
    public title: string = '';
    public notes: string = '';
    public requestDates: Date[] = [];
    public uploadedFiles: File[] = [];
    public maxAmount: number;
    public leftAmount: number;
    public requestForm: FormGroup;
    public router: Router;
    public token: string;

    constructor(
        public app: AppComponent,
        public RequestService: RequestService,
        public message: MessageService,
        private translate: TranslateService,
        private routerNav: Router,
        private msalService: MsalService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    ) {
        this.router = routerNav;
        this.translateService = translate;
        this.messageService = message;
        this.requestService = RequestService;
        this.requestForm = new FormGroup({
            username: new FormControl('', [Validators.required]),
        });

        this.componentInstance = app;
    }

    async ngOnInit() {
        this.token = await this.getToken();
        await this.getConfig();
    }

    async getToken(): Promise<string> {
        let activeAccount = this.msalService.instance.getActiveAccount();

        if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
            let accounts = this.msalService.instance.getAllAccounts();
            this.msalService.instance.setActiveAccount(accounts[0]);
            activeAccount = this.msalService.instance.getActiveAccount();
        }

        this.currentUserName = activeAccount.name;
        this.currentUserMail = activeAccount.username;
        return this.msalService.instance.acquireTokenSilent({
            scopes: ['openid', 'profile', 'user.read'],
        })
            .then((response) => {
                return response.accessToken;
            })
            .catch((error) => {
                return null;
            });
    }

    onFileSelect(event: any) {
        this.uploadedFiles = [];
        if (event.files) {
            for (let i = 0; i < event.files.length; i++) {
                let fileup = <File>event.files[i];
                event.files[i]['guid'] = uuidv4();
                fileup['guid'] = event.files[i]['guid'];
                this.uploadedFiles.push(fileup);
            }
        }
    }
    onFileRemove(event: any) {
        this.uploadedFiles = this.uploadedFiles.filter(
            (f) => f['guid'] != event.file['guid']
        );
    }

    async getConfig() {
        this.loading = true;
        this.requestService
            .getConfig(this.token)
            .pipe(
                tap((res) => {
                    this.maxAmount = res.data.maxAmount;
                    this.leftAmount = (res.data.maxAmount*100 - res.data.requestedAmount*100)/100;

                    this.availableCategories = [];
                    for (let i = 0; i < res.data.categories.length; i++) {
                        const category = res.data.categories[i];
                        this.availableCategories.push({
                            label: category.label,
                            value: category.id,
                        } as DropdownItem);
                    }
                }),
                catchError((res) => {
                    if (res.status == 401) {
                        this.showUnauthorizedMessage();
                        return;
                    }
                    this.showErrorToast(
                        this.translateService.instant(
                            'Impossibile recuperare la configurazione'
                        )
                    );
                    return of();
                }),
                finalize(() => {
                    this.loading = false;
                    return of();
                })
            )
            .subscribe();
    }

    showUnauthorizedMessage() {
        Swal.fire({
            title: this.translateService.instant("L'utenza corrente non è autorizzata ad utilizzare questa applicazione"),
            icon: 'error',
            confirmButtonText: this.translateService.instant('Logout'),
            showCancelButton: false,
            buttonsStyling: false,
            reverseButtons: true,
            focusConfirm: true,
            customClass: {
                popup: 'w-50-em',
                confirmButton: 'flex oris-button oris-green',
            },
            didDestroy: () => {
                this.logout();
            }
        });
    }

    logout() {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            this.msalService.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else {
            this.msalService.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }

    showErrorToast(description: string) {
        this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('Errore'),
            detail: description,
        });
    }

    onSubmit(isValid) {
        let fileNotLoaded = (this.uploadedFiles == null || this.uploadedFiles.length < 1);
        if (fileNotLoaded) {
            this.invalidFile = true;
        }
        if (!isValid || fileNotLoaded) {
            this.submitError = true;
            return;
        }

        this.submitError = false;
        this.invalidFile = false;

        if (this.amount > this.leftAmount) {
            this.showErrorToast(this.translateService.instant('Il massimo importo richiedibile è: ' + this.leftAmount));
            return;
        }

        const formData = new FormData();
        formData.append('Title', this.title);
        formData.append('Notes', this.notes);
        formData.append('Amount', this.amount.toFixed(2));
        formData.append('DateStart', this.requestDates[0].toISOString());
        formData.append('DateEnd', (this.requestDates[1] ?? this.requestDates[0]).toISOString());
        this.selectedCategories.forEach((categoryId, index) => {
            formData.append(`CategoryIds[${index}]`, categoryId.toString());
        });
        for (let i = 0; i < this.uploadedFiles.length; i++) {
            const file = this.uploadedFiles[i] as File;
            formData.append(`Attachments`, file);
        }

        this.loading = true;
        this.requestService
            .createRequest(this.token, formData)
            .pipe(
                tap((res) => {
                    this.router.navigateByUrl('/');
                }),
                catchError((res) => {
                    return of();
                }),
                finalize(() => {
                    this.loading = false;
                    return of();
                })
            )
            .subscribe();
    }
}

interface DropdownItem {
    label: string;
    value: number;
}
